import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../globalComponents/Button";
import { MessageTypes } from "../toast-message/toast-message";
import { IConfirmType } from "../../hooks/confirm-dialog-hook/use-confirm-dialog";
import { colors } from "../GlobalStyle/GlobalStyle";
interface IProps {
  confirmTitle: string;
  confirmParagraph: string;
  confirmType: IConfirmType;
  messageTypes: MessageTypes;
  handleIsOpen: (value: boolean) => void;
  handleConfirm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isCancel?: boolean;
  confirmButtonColor?: string;
}

const Container = styled.div`
  display: flex;
  z-index: 9999;
  flex: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
`;

const DialogContainer = styled.div`
  display: flex;
  width: auto;
  max-width: 90%; // 필요에 따라 최대 너비 제한 설정
  background-color: white;
  box-shadow: ${props => `0px 80px 0px -69px ${props.color} inset`};
  -webkit-box-shadow: ${props => `0px 80px 0px -69px ${props.color} inset`};
  -moz-box-shadow: ${props => `0px 80px 0px -69px ${props.color} inset`};
  border-radius: 4px;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
`;

const Footer = styled.footer`
  display: flex;
  flex: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  justify-content: flex-end;
  align-items: center;
  button {
    margin: 6px;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  flex: 15;
  margin-bottom: 20px;
`;

const TitleIconContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TitleContent = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 5px;
`;

const Title = styled.h2`
  margin-top: 0px;
  margin-bottom: 0px;
`;

const Content = styled.p`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: pre;
`;

const Icon = styled(FontAwesomeIcon)<{ messagetypes: MessageTypes }>`
  margin-left: 5%;
  background-color: white;
  border-radius: 50%;
  clip-path: ${props =>
    props.messagetypes === MessageTypes.WARNING
      ? "polygon(50% 0%, 0% 100%, 100% 100%)"
      : "none"};
`;

const ConfirmDialog = <P extends IProps>({
  confirmTitle,
  confirmParagraph,
  confirmType,
  messageTypes,
  handleIsOpen,
  handleConfirm,
  confirmButtonColor,
  isCancel
}: P) => {
  return (
    <Container>
      <DialogContainer color={confirmType.iconColor}>
        <TitleContainer>
          <TitleIconContainer>
            <Icon
              icon={confirmType.icon}
              size="3x"
              color={confirmType.iconColor}
              messagetypes={messageTypes}
            />
          </TitleIconContainer>
          <TitleContent>
            <Title>{confirmTitle}</Title>
            <Content>{confirmParagraph}</Content>
          </TitleContent>
        </TitleContainer>
        <Footer>
          {!isCancel && (
            <Button
              backgroundColor={colors.darkRed}
              hoverBackgroundColor={colors.lightRed}
              onClick={() => handleIsOpen(false)}
            >
              취소
            </Button>
          )}
          <Button
            backgroundColor={confirmButtonColor || colors.darkBlue}
            hoverBackgroundColor={colors.skyBlue}
            onClick={event => {
              event.preventDefault();
              handleConfirm(event);
            }}
          >
            확인
          </Button>
        </Footer>
      </DialogContainer>
    </Container>
  );
};

export default ConfirmDialog;
