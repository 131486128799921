import uiString from "./string.json";
import * as XLSX from "xlsx";
import Papa from "papaparse";

export const initialQueryContent = {
  title: uiString.textFields.noSelection,
  description: uiString.textFields.noSelection,
  base_query: uiString.textFields.noSelection,
  employees: [],
  departments: [],
  approval_result: "",
  start_date: "",
  end_date: "",
  publish: 0
};

export const queryContentReducer = (state, action) => {
  switch (action.type) {
    case "title":
      return {
        ...state,
        title: action.title
      };
    case "description":
      return {
        ...state,
        description: action.description
      };
    case "base_query":
      return {
        ...state,
        base_query: action.base_query
      };
    case "employees":
      return {
        ...state,
        employees: action.employees
      };
    case "departments":
      return {
        ...state,
        departments: action.departments
      };
    case "approval_result":
      return {
        ...state,
        approval_result: action.approval_result
      };
    case "start_date":
      return {
        ...state,
        start_date: action.start_date
      };
    case "end_date":
      return {
        ...state,
        end_date: action.end_date
      };
    case "publish":
      return {
        ...state,
        publish: action.publish
      };
    default:
      throw new Error();
  }
};

export const downloadFileFromServer = (csvData, filename) => {
  const parsedData = Papa.parse(csvData, {
    skipEmptyLines: true
  }).data;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(parsedData);

  const timeRegex = /^([0-9]{1,3}):([0-5][0-9])$/;
  const wonRegex = /^₩\s*([0-9,]+)$/;

  for (let cell in ws) {
    if (cell[0] === "!") continue;

    const timeMatch = timeRegex.exec(ws[cell].v);
    const wonMatch = wonRegex.exec(ws[cell].v);

    if (timeMatch) {
      const hours = parseInt(timeMatch[1]);
      const minutes = parseInt(timeMatch[2]);
      ws[cell].v = hours / 24 + minutes / 1440;
      ws[cell].t = "n";
      ws[cell].z = "[h]:mm";
    } else if (wonMatch) {
      ws[cell].v = Number(wonMatch[1].replace(/,/g, ""));
      ws[cell].t = "n";
      ws[cell].z = '_-* #,##0_-;-* #,##0_-;_-* "-"_-;_-@_-';
    }
  }

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });

  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename + ".xlsx");
  } else {
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = filename + ".xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
