import { useForm } from "react-hook-form";
import styled from "styled-components";
import { faUserPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CheckBox from "./check-box";
import ReactTooltip from "react-tooltip";
import IconButton from "../../../../globalComponents/IconButton";
import { useCallback } from "react";
import { IApprovalNotificationTemplate } from "../../../../../hooks/approval-notification-management-hook/use-approval-notification-template";
import * as ReactTable from "react-table";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import { gql, useReactiveVar, useMutation } from "@apollo/client";
import {
  AddEmployeeAppliedToTheTemplate,
  AddEmployeeAppliedToTheTemplateVariables
} from "../../../../../__generated__/AddEmployeeAppliedToTheTemplate";
import { IsException } from "../../../../../__generated__/globalTypes";
import {
  DeleteEmployeeApplied,
  DeleteEmployeeAppliedVariables
} from "../../../../../__generated__/DeleteEmployeeApplied";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import { colors } from "../../../../GlobalStyle/GlobalStyle";

interface IProps {
  selectedTemplate?: IApprovalNotificationTemplate;
  selectedUser: ReactTable.Row<any>;
  cacheId?: string | null;
}
interface IApprovalNotificationEmployeeFieldValues {
  isExpect: boolean;
}

const Container = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

const MUTATION_ADD_EMPLOYEE_APPLIED_TO_THE_TEMPLATE = gql`
  mutation AddEmployeeAppliedToTheTemplate(
    $templateIdx: Float!
    $selectedEmployeeId: String!
    $isExpect: IsException!
  ) {
    addEmployeeAppliedToTheTemplate(
      templateIdx: $templateIdx
      selectedEmployeeId: $selectedEmployeeId
      isExpect: $isExpect
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_EMPLOYEE_APPLIED = gql`
  mutation DeleteEmployeeApplied(
    $templateIdx: Float!
    $selectedEmployeeId: String!
  ) {
    deleteEmployeeApplied(
      templateIdx: $templateIdx
      selectedEmployeeId: $selectedEmployeeId
    ) {
      ok
      error
    }
  }
`;

function ApprovalNotificationFooter({
  selectedTemplate,
  selectedUser
}: IProps) {
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const selectedListOfEmployee = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeVar
  );
  const [addEmployeeAppliedToTheTemplate, { client }] = useMutation<
    AddEmployeeAppliedToTheTemplate,
    AddEmployeeAppliedToTheTemplateVariables
  >(MUTATION_ADD_EMPLOYEE_APPLIED_TO_THE_TEMPLATE, {
    async update(_, { data }) {
      const addedUser =
        selectedListOfEmployee[selectedListOfEmployee.length - 1];
      if (
        data?.addEmployeeAppliedToTheTemplate.ok &&
        addedUser.original.employeeId &&
        selectedTemplate
      ) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${
            selectedListOfEmployeeId[selectedListOfEmployeeId.length - 1]
          }를 추가했습니다.`,
          MessageTypes.SUCCESS
        );
        await client.resetStore();
      } else if (
        !data?.addEmployeeAppliedToTheTemplate.ok &&
        selectedTemplate?.alarmName
      ) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${
            selectedListOfEmployeeId[selectedListOfEmployeeId.length - 1]
          }를 추가하지 못했습니다.`,
          MessageTypes.ERROR
        );
      }
    }
  });

  const [deleteEmployeeApplied] = useMutation<
    DeleteEmployeeApplied,
    DeleteEmployeeAppliedVariables
  >(MUTATION_DELETE_EMPLOYEE_APPLIED, {
    update(cache, { data }) {
      if (data?.deleteEmployeeApplied.ok && selectedTemplate) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedUser.original.name}님 데이터를 삭제 했습니다.`,
          MessageTypes.SUCCESS
        );
        cache.evict({
          id: `EmployeeAppliedToTheTemplate:${selectedUser.original.employeeId}`
        });
        cache.modify({
          id: `CountListOfEmployeeAppliedToTheTemplateOutput`,
          fields: {
            total(prev) {
              return prev - 1;
            }
          }
        });
      } else if (!data?.deleteEmployeeApplied.ok && selectedTemplate) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedUser.original.name}님 데이터를 삭제하지 못했습니다.`,
          MessageTypes.ERROR
        );
      }
    }
  });

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();
  const { register, handleSubmit } =
    useForm<IApprovalNotificationEmployeeFieldValues>();
  const addEmployeesAppliedToTheTemplate = useCallback(
    (data: IApprovalNotificationEmployeeFieldValues) => {
      if (selectedTemplate && selectedListOfEmployee) {
        addEmployeeAppliedToTheTemplate({
          variables: {
            templateIdx: selectedTemplate.templateIdx,
            selectedEmployeeId:
              selectedListOfEmployee[selectedListOfEmployee.length - 1].original
                .employeeId,
            isExpect: data.isExpect
              ? IsException.EXCEPTION
              : IsException.INCLUDING
          }
        });
      }
    },
    [selectedTemplate, selectedListOfEmployee, addEmployeeAppliedToTheTemplate]
  );

  const deleteEmployeesAppliedToTheTemplate = useCallback(() => {
    if (selectedTemplate && selectedUser) {
      deleteEmployeeApplied({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          selectedEmployeeId: selectedUser.original.employeeId
        }
      });
    }
  }, [selectedTemplate, selectedUser, deleteEmployeeApplied]);
  return (
    <Container>
      <CheckBox {...register("isExpect")} title="예외직원" />
      <IconButton
        icon={faUserPlus}
        onClick={handleSubmit(addEmployeesAppliedToTheTemplate)}
        data-tip
        data-for="addUser"
        disabled={
          !selectedTemplate ||
          !selectedListOfEmployeeId ||
          !selectedListOfEmployeeId[selectedListOfEmployeeId.length - 1]
        }
      />
      <ReactTooltip type="success" id="addUser">
        사용자 추가
      </ReactTooltip>
      {selectedTemplate && selectedUser && (
        <ReactTooltip id="delete" type="success">
          <span>사용자 삭제</span>
        </ReactTooltip>
      )}
      <IconButton
        icon={faTrashAlt}
        color={colors.darkRed}
        onClick={handleSubmit(deleteEmployeesAppliedToTheTemplate)}
        data-tip
        data-for="delete"
        disabled={!selectedTemplate || !selectedUser}
      />
      <ToastMessage
        messageTypes={toastMessageType}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
}

export default ApprovalNotificationFooter;
