import * as React from "react";

type Menu = {
  calendar: boolean;
  workingTemplate: boolean;
  pcInfo: boolean;
  serviceManagementInfo: boolean;
  commutingManagementInfo: boolean;
  placeFreeManagement: boolean;
  additionalInformationManagement: boolean;
  approvalNotification: boolean;
  workTimeInformation: boolean;
  annualStatus: boolean;
  approvalStatus: boolean;
  weeklyWork: boolean;
};

type Props = {
  webEtcFlag: number;
};

export enum WEB_ETC_FLAG_VALUES {
  CALENDAR = 1,
  SET_SMTP_FOR_EMAIL_NOTIFICATION = 2,
  SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION = 4,
  DESIGNATE_PROXY_APPROVER = 8,
  SERVICE_MANAGEMENT_INFO_VALUE = 16, // 판넬 근무관리정보 메뉴
  COMMUTING_MANAGEMENT_INFO_VALUE = 32, // 판넬 출퇴근관리정보 메뉴
  PLACE_FREE_MANAGEMENT_VALUE = 64, // 판넬 이석관리 메뉴
  ADDITIONAL_INFORMATION_MANAGEMENT = 128, // 판넬 추가정보관리 메뉴
  WEB_CHAT_ETC_FLAG = 8192,
  APPROVAL_NOTIFICATION_VALUE = 131072, // 판넬 결재알림관리 메뉴
  WORKING_TEMPLATE = 32768, // 판넬 근무제정보 메뉴
  PC_INFO = 65536, // 판넬 PC정보 메뉴
  WORK_TIME_INFORMATION = 262144, // 판넬 근무시간정보 메뉴
  ANNUAL_STATUS = 524288, // 판넬 연차현황 메뉴
  APPROVAL_STATUS = 1048576, // 판넬 결재현황현황 메뉴
  WEEKLY_WORK = 4194304 // 판넬 주별근무(수당정보) 메뉴
}

const useMenuByWebEtcFlag = <P extends Props>({ webEtcFlag }: P) => {
  const isMount = React.useRef<boolean>(false);
  const [menu, setMenu] = React.useState<Menu>({
    calendar: false,
    workingTemplate: false,
    pcInfo: false,
    serviceManagementInfo: false,
    commutingManagementInfo: false,
    placeFreeManagement: false,
    additionalInformationManagement: false,
    approvalNotification: false,
    workTimeInformation: false,
    annualStatus: false,
    approvalStatus: false,
    weeklyWork: false
  });

  const handleMenu = React.useCallback(() => {
    const calculateWebEtcFlag = (value: number) => {
      return webEtcFlag & value ? true : false;
    };
    const newMenu = {
      calendar: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.CALENDAR),
      workingTemplate: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.WORKING_TEMPLATE
      ),
      pcInfo: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.PC_INFO),
      setSMTPForEmailNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SET_SMTP_FOR_EMAIL_NOTIFICATION
      ),
      setCreatorListForEmailNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION
      ),
      designateProxyApprover: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.DESIGNATE_PROXY_APPROVER
      ),
      serviceManagementInfo: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.SERVICE_MANAGEMENT_INFO_VALUE
      ),
      commutingManagementInfo: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.COMMUTING_MANAGEMENT_INFO_VALUE
      ),
      placeFreeManagement: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.PLACE_FREE_MANAGEMENT_VALUE
      ),
      additionalInformationManagement: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.ADDITIONAL_INFORMATION_MANAGEMENT
      ),
      approvalNotification: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.APPROVAL_NOTIFICATION_VALUE
      ),
      workTimeInformation: calculateWebEtcFlag(
        WEB_ETC_FLAG_VALUES.WORK_TIME_INFORMATION
      ),
      annualStatus: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.ANNUAL_STATUS),
      approvalStatus: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.APPROVAL_STATUS),
      weeklyWork: calculateWebEtcFlag(WEB_ETC_FLAG_VALUES.WEEKLY_WORK)
    };

    if (isMount.current) {
      setMenu(newMenu);
    }
  }, [webEtcFlag]);

  React.useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
    }
    handleMenu();
    return () => {
      isMount.current = false;
    };
  }, [handleMenu]);

  return { menu };
};

export default useMenuByWebEtcFlag;
