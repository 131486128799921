import { useReactiveVar } from "@apollo/client";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Reducers } from "../../../../../../types/reducers";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import useAdditionalInformationManagementData from "../../../../../hooks/additional-information-management-hooks/use-additional-information-management-data";
import useAimMultiTab from "../../../../../hooks/additional-information-management-hooks/use-aim-multi-tab";
import useCheckWebAuthFn, {
  WebMenuAuthValueList
} from "../../../../../hooks/use-check-web-auth-fn";
import useIsLoading from "../../../../../hooks/use-is-loading";
import { Role } from "../../../../../user-types";
import MultiTab from "../../../../multi-tab";
import Loader from "../../Loader";
import BasicInformationManagement from "./basic-information-management";
import WorkInformationManagement from "./work-information-management";
import { useEffect, useMemo } from "react";

interface IProps {}

const Container = styled.div`
  display: flex;
  flex: 10;
  flex-direction: column;
`;

const Contents = styled.div`
  display: flex;
  flex: 10;
  padding: 10px;
`;

const TitleBar = styled.div`
  display: flex;
  border-bottom: 4px solid #bdc3c7;
  margin: 20px 0px;
`;

const AdditionalInformationManagement = <P extends IProps>(props: P) => {
  const {
    signInReducer: {
      employee_id,
      webMenuList,
      checkWebAuthFn: { working_auth_web },
      loginIdInfo
    }
  } = useSelector((state: Reducers) => state);
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: working_auth_web,
    webEtcFlag: webMenuList[0].web_etc_flag
  });
  const { list, setList, selectedItem, handleSelected } = useAimMultiTab({
    menuList: [{ name: "인사정보", isSelected: true }]
  });
  const { isLoading, handleIsLoading } = useIsLoading();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { workInformation } = useAdditionalInformationManagementData({
    employeeId:
      loginIdInfo === Role.SUPER_ADMIN
        ? selectedListOfEmployeeId.at(-1) ?? ""
        : employee_id,
    handleIsLoading,
    isUpdateBasic: webMenuAuthList.isUpdateBasicInformationManagementButton,
    isUpdateWork: webMenuAuthList.isUpdateWorkInformationManagementButton
  });

  const employeeId: string = useMemo(
    () =>
      loginIdInfo === Role.SUPER_ADMIN
        ? selectedListOfEmployeeId.at(-1) ?? ""
        : employee_id,
    [selectedListOfEmployeeId, employee_id]
  );

  useEffect(() => {
    if (
      webMenuList[0]?.web_etc_flag &&
      Boolean(
        webMenuList[0]?.web_etc_flag & WebMenuAuthValueList.annualSettingEtcFlag
      )
    ) {
      setList(data => {
        let newList = [...data];
        newList.push({ name: "연차정보", isSelected: false });
        return newList;
      });
    }
  }, [webMenuList]);

  return (
    <Container>
      <TitleBar>
        <MultiTab list={list} handleSelected={handleSelected} />
      </TitleBar>
      <Contents id="additional-information-management-contents">
        {isLoading && <Loader />}
        {selectedItem?.name === "인사정보" && !isLoading && (
          <BasicInformationManagement employeeId={employeeId} />
        )}
        {selectedItem?.name === "연차정보" && !isLoading && (
          <WorkInformationManagement
            employeeId={employeeId}
            isUpdateButton={
              webMenuAuthList.isUpdateWorkInformationManagementButton
            }
            workInformation={workInformation}
          />
        )}
      </Contents>
    </Container>
  );
};

export default AdditionalInformationManagement;
