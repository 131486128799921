import styled from "styled-components";
import * as ReactTable from "react-table";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import StyledButton from "../../../../shared/styled_button";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import { useReactiveVar } from "@apollo/client";
import useOpenDialog from "../../../../../hooks/use-open-dialog";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import useConfirmDialog from "../../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  AnnualStatusEntity,
  EditAnnualStatusMutationVariables,
  UserDailyWorkPlanEntity,
  useEditAnnualStatusMutation,
  useGetAnnualStatusListCountLazyQuery,
  useGetAnnualStatusListExcelLazyQuery,
  useGetAnnualStatusListLazyQuery
} from "../../../../../generated/graphql";
import { downloadFileFromServer } from "../../../statistics/Utils";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import Spinner from "../../../../shared/spinner";
import PageController from "../../../../table/page_controller";
import ButtonContainer from "../../../../shared/button_container";
import AnnualStatusHeader from "./annual_status_header";
import AnnualStatusDialogEditor from "./annual_status_dialog_editor";
import ConfirmDialog from "../../../../confirm-dialog/confirm-dialog";
import useNewSortBy from "../../../../../hooks/use-new-sort-by/use-new-sort-by";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import TableV2 from "../../../../table_v2/table_v2";
import { AutoSizer } from "react-virtualized";
import { useSticky } from "react-table-sticky";

const COLUMN_FOR_VALUE = {
  deptName: "부서이름",
  employeeId: "사번",
  empName: "사용자이름",
  joiningDate: "입사일",
  applyYear: "귀속년도",
  annualDay: "총 발생일",
  annualRemainingDay: "잔여일",
  annualUseDay: "사용일",
  descr: "비고"
} as const;

type TYPE_OF_ANNUAL_STATUS = keyof typeof COLUMN_FOR_VALUE;

export interface IUserDailyWorkPlanEntity
  extends Partial<UserDailyWorkPlanEntity> {
  isCheck?: boolean;
}
const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  width: 100%;
  will-change: scroll-position;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

const BtnContainer = styled(ButtonContainer)`
  padding: 10px;
`;

function AnnualStatus() {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const { fieldSort, handleFieldSort } = useNewSortBy();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [year, setYear] = useState(moment().subtract(1, "day").format("YYYY"));
  const [searchYear, setSearchYear] = useState(
    moment().subtract(1, "day").format("YYYY")
  );
  const [userListForAdd, setUserListForAdd] = useState<string[]>([]);

  const [isSearch, setIsSearch] = useState<boolean>(true);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { isOpen, handleOpenDialog } = useOpenDialog();
  const { isOpen: editIsOpen, handleOpenDialog: handleOpenEditDialog } =
    useOpenDialog();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isConfirmOpen,
    handleIsOpen: handleIsConfirmOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getAnnualStatusListCount, { data: totalCountData }] =
    useGetAnnualStatusListCountLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const [getAnnualStatusList, { data, loading }] =
    useGetAnnualStatusListLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
        setIsSearch(false);
      },
      onCompleted() {
        setIsSearch(false);
      }
    });

  const [getAnnualStatusListExcel, { client }] =
    useGetAnnualStatusListExcelLazyQuery({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 연차현황 정보를 엑셀로 다운로드 하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      onCompleted(data) {
        if (
          data.getAnnualStatusListExcel.ok &&
          data.getAnnualStatusListExcel.excel
        ) {
          downloadFileFromServer(
            data.getAnnualStatusListExcel.excel,
            `${moment().format(
              "YYYY-MM-DD-hh-mm-ss"
            )})_list_of_annual_status.csv`
          );
        } else if (data.getAnnualStatusListExcel.error) {
          handleToast(data.getAnnualStatusListExcel.error, MessageTypes.ERROR);
        }
      }
    });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getAnnualStatusListExcel({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchYear: year
        }
      });
    }
  }, [getAnnualStatusListExcel, selectedListOfEmployeeId, year]);

  const count: number = useMemo(() => {
    return totalCountData?.getAnnualStatusListCount.count ?? 0;
  }, [totalCountData]);

  const columns: ReactTable.Column<AnnualStatusEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const smallWidth = 70;
    let newListOfColumn: ReactTable.Column<AnnualStatusEntity>[] =
      listOfColumn.map((item, index) => {
        let width: number = 120;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
          COLUMN_FOR_VALUE.annualRemainingDay
        ) {
          width = smallWidth;
        }
        let sticky = "";
        if (sFixedColumnNumber) {
          if (index + 1 <= sFixedColumnNumber) {
            sticky = "left";
          }
        }
        return {
          Header(header) {
            return COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS];
          },
          accessor: item as TYPE_OF_ANNUAL_STATUS,
          Cell(cell: Cell<AnnualStatusEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_ANNUAL_STATUS] ===
              COLUMN_FOR_VALUE.descr
            ) {
              if (cell.value) {
                const maxLength = 12;
                const description = cell.value
                  .replace(/\/nn/g, "\n")
                  .indexOf("\n");
                if (description.length > maxLength) {
                  return description.substring(0, maxLength) + "...";
                }
                if (description !== -1) {
                  return (
                    cell.value
                      .replace(/\/nn/g, "\n")
                      .substring(0, description) + "..."
                  );
                }
              }
            }
            return cell.value ?? "";
          },
          width,
          sticky
        };
      });
    return newListOfColumn;
  }, [sFixedColumnNumber]);

  const list = useMemo(() => {
    return data?.getAnnualStatusList.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<AnnualStatusEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow: ReactTable.Row<AnnualStatusEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  const [editAnnualStatus] = useEditAnnualStatusMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알 수 없는 이유로 연차 정보를 추가 및 수정하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    update(_, { data }) {
      if (data?.editAnnualStatus.ok) {
        handleToast(
          "성공적으로 연차 정보를 추가 및 수정하였습니다",
          MessageTypes.SUCCESS
        );
        client.resetStore();
        handleOpenDialog(false);
        handleOpenEditDialog(false);
      } else if (data?.editAnnualStatus.error) {
        handleToast(data?.editAnnualStatus.error, MessageTypes.ERROR);
      }
    }
  });

  const handleAddAnnualStatus = useCallback(
    (payload: Omit<EditAnnualStatusMutationVariables, "employeeIdList">) => {
      editAnnualStatus({
        variables: {
          employeeIdList: userListForAdd,
          searchYear: payload.searchYear,
          annualDay: payload.annualDay,
          descr: payload.descr
        }
      });
    },
    [userListForAdd, handleToast, client]
  );

  const handleEditAnnualStatus = useCallback(
    (payload: Omit<EditAnnualStatusMutationVariables, "employeeIdList">) => {
      if (selectedRow) {
        editAnnualStatus({
          variables: {
            employeeIdList: [selectedRow.original.employeeId],
            searchYear: payload.searchYear,
            annualDay: payload.annualDay,
            descr: payload.descr
          }
        });
      }
    },
    [selectedRow]
  );

  const handleDeleteAnnualStatus = useCallback(() => {
    if (table.selectedFlatRows) {
      const employeeIdList = table.selectedFlatRows.map(
        item => item.original.employeeId ?? ""
      );
      editAnnualStatus({
        variables: {
          employeeIdList,
          searchYear: year,
          annualDay: 0,
          descr: ""
        }
      })
        .then(({ data }) => {
          if (data?.editAnnualStatus.ok) {
            handleToast(
              "성공적으로 연차 정보를 삭제하였습니다.",
              MessageTypes.SUCCESS
            );
            client.resetStore();
          } else if (data?.editAnnualStatus.error) {
            handleToast(data?.editAnnualStatus.error, MessageTypes.ERROR);
          }
        })
        .catch(error => {
          console.log(error);
          handleToast(
            "알 수 없는 이유로 연차 정보를 삭제하지 못했습니다.",
            MessageTypes.ERROR
          );
        })
        .finally(() => {
          handleIsConfirmOpen(false);
        });
    }
  }, [table.selectedFlatRows, year]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (isSearch) {
      setSearchYear(year);
    }
  }, [isSearch, year]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getAnnualStatusList({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchYear,
          page: currentPage,
          take,
          fieldSort
        }
      });
    }
  }, [
    getAnnualStatusList,
    searchYear,
    selectedListOfEmployeeId,
    isSearch,
    currentPage,
    take,
    fieldSort
  ]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length) {
      getAnnualStatusListCount({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          searchYear
        }
      });
    }
  }, [getAnnualStatusListCount, selectedListOfEmployeeId, searchYear]);

  return (
    <Container>
      <AnnualStatusHeader<AnnualStatusEntity>
        year={year}
        setYear={setYear}
        columns={table.columns as TColumn<AnnualStatusEntity>[]}
        table={table}
        setIsSearch={setIsSearch}
        title="연차현황"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleToast={handleToast}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="연차현황"
                  selectedRow={selectedRow}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  height={height}
                  width={width}
                  loading={loading}
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
        <BtnContainer>
          <StyledButton
            onClick={() => {
              handleOpenDialog(true);
            }}
          >{`추가`}</StyledButton>
          <StyledButton
            onClick={() => {
              handleOpenEditDialog(true);
            }}
            disabled={table.selectedFlatRows.length !== 1}
          >{`수정`}</StyledButton>
          <StyledButton
            disabled={table.selectedFlatRows.length < 1}
            onClick={() => {
              handleConfirmMessage({
                title: "연차현황 정보 삭제",
                p: "선택된 연차 현황 정보를 삭제하시겠습니까?",
                messageTypes: MessageTypes.WARNING
              });
              handleIsConfirmOpen(true);
            }}
          >{`삭제`}</StyledButton>
        </BtnContainer>
      </TableFooter>
      {isOpen && (
        <AnnualStatusDialogEditor
          title="연차정보추가"
          btnTitle="추가"
          handleOpenDialog={handleOpenDialog}
          handleEditor={handleAddAnnualStatus}
          setUserListForAdd={setUserListForAdd}
        />
      )}
      {editIsOpen && (
        <AnnualStatusDialogEditor
          title="연차정보수정"
          btnTitle="저장"
          handleOpenDialog={handleOpenEditDialog}
          handleEditor={handleEditAnnualStatus}
          selectedRow={selectedRow}
        />
      )}
      {isConfirmOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsConfirmOpen}
          handleConfirm={handleDeleteAnnualStatus}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default AnnualStatus;
