import styled from "styled-components";
import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";
import { COLUMN_FOR_VALUE, TABLE_CONFIG } from "./constants";
import { IClosingInfoEntity, TYPE_OF_EMPLOYEE } from "./types";
import * as ReactTable from "react-table";
import StyledInput from "../../../../../components/shared/styled_input";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import { Is_Active } from "../../../../../generated/graphql";

const CheckBoxContainer = styled(StyledFlexContainer)`
  justify-content: center;
  gap: 0.5em;
`;

export const createColumns = (sFixedColumnNumber: number) => {
  const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
  const smallWidth = TABLE_CONFIG.MIN_COLUMN_WIDTH;
  const largeWidth = TABLE_CONFIG.DEFAULT_COLUMN_WIDTH + 40;
  let newListOfColumn: ReactTable.Column<IClosingInfoEntity>[] =
    listOfColumn.map((item, index) => {
      let width: number = TABLE_CONFIG.DEFAULT_COLUMN_WIDTH;
      if (
        COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] === COLUMN_FOR_VALUE.isCheck
      ) {
        width = smallWidth;
      }

      if (
        COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.holidayBasicMinLaborday ||
        COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.holidayExtMinLaborday ||
        COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
          COLUMN_FOR_VALUE.holidayNightMinLaborday
      ) {
        width = largeWidth;
      }

      let sticky = "";
      if (sFixedColumnNumber) {
        if (index + 1 <= sFixedColumnNumber) {
          sticky = "left";
        }
      }

      return {
        Header(header) {
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.isCheck
          ) {
            return (
              <CheckBoxContainer>
                <StyledInput
                  type="checkbox"
                  checked={header.isAllRowsSelected}
                  onChange={() => {
                    if (header.isAllRowsSelected) {
                      header.toggleAllRowsSelected(false);
                    } else {
                      header.toggleAllRowsSelected(true);
                    }
                  }}
                />
                <span>{COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}</span>
              </CheckBoxContainer>
            );
          }
          return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
        },
        accessor: item as TYPE_OF_EMPLOYEE,
        Cell(cell: Cell<IClosingInfoEntity>) {
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.isCheck
          ) {
            return (
              <StyledInput
                type="checkbox"
                checked={cell.row.isSelected}
                onChange={event => {
                  event.stopPropagation();
                  if (cell.row.isSelected) {
                    cell.row.toggleRowSelected(false);
                  } else {
                    cell.row.toggleRowSelected(true);
                  }
                }}
              />
            );
          }
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.isClosingStatus
          ) {
            return cell.value === Is_Active.Active ? "마감" : "미마감";
          }
          return cell.value ?? "";
        },
        width,
        sticky
      };
    });
  return newListOfColumn;
};
