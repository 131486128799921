import * as React from "react";
import { ListItem } from "../../../../hooks/use-super-admin/use-web-menu-list";
import Button from "../../../globalComponents/Button";
import {
  Container,
  CheckBoxContainer,
  CheckBox,
  CheckMark,
  ContentContainer,
  ContentTitle,
  Title,
  ListContainer,
  ListTitle,
  ButtonContainer,
  RadioButtonGroup
} from "./styled";
import useOpenDialog from "../../../../hooks/use-open-dialog/use-open-dialog";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import SuperAdminPermissionSetting from "../super_admin_permission_setting/super_admin_permission_setting";
import StyleInput from "../../../inputs/style-input";
import RadioContainer from "../../../shared/radio_container/radio_container";
import styled from "styled-components";
import uiString from "../string.json";

type OnChange = React.ChangeEvent<HTMLInputElement>;

const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatisticsLabel = styled.label`
  margin-right: 10px;
  font-weight: bold;
`;

interface IProps {
  title?: string;
  list?: ListItem[];
  handleOnChange: (
    event: OnChange,
    radioCategory?: string,
    status?: number
  ) => void;
  updateMenuList?: () => void;
}

// 메뉴 숨김 작동은 LeftMenuList.jsx에서 처리
const MenuCheckBoxList = (props: IProps) => {
  const { title, list, handleOnChange, updateMenuList } = props;
  const { isOpen, handleOpenDialog } = useOpenDialog();
  return (
    <Container>
      <Title>{title}</Title>
      {list &&
        list.map((item, index) => (
          <ListContainer key={index}>
            <ListTitle>{item.mainTitle}</ListTitle>
            {item.data.map((subItem, subindex) => (
              <ContentContainer key={subindex}>
                {subItem.isRadioBtn === true ? (
                  <StatisticsContainer>
                    <StatisticsLabel>{subItem.title}</StatisticsLabel>
                    <RadioButtonGroup>
                      {subItem.radioBtnList?.map(
                        ({ radioTitle, radioValue }, optionIndex) => {
                          return (
                            <RadioContainer key={radioValue}>
                              <StyleInput
                                type="radio"
                                title={subItem.title}
                                value={radioValue}
                                id={`${subItem.key}-${subItem.radioCategory}-${radioValue}`}
                                name={subItem.key}
                                minWidth="20px"
                                checked={subItem.whichRadioBtn === radioValue}
                                onChange={event =>
                                  handleOnChange(
                                    event,
                                    subItem.radioCategory,
                                    subItem.status
                                  )
                                }
                              />
                              <label htmlFor={`${subItem.key}-${radioValue}`}>
                                {radioTitle}
                              </label>
                            </RadioContainer>
                          );
                        }
                      )}
                    </RadioButtonGroup>
                  </StatisticsContainer>
                ) : (
                  <>
                    <CheckBoxContainer>
                      <CheckBox
                        type="checkbox"
                        title={subItem.title}
                        id={subItem.key}
                        name={subItem.key}
                        value={subItem.status}
                        checked={subItem.isCheck}
                        onChange={event => handleOnChange(event)}
                      />
                      <CheckMark />
                    </CheckBoxContainer>
                    <ContentTitle>{subItem.title}</ContentTitle>
                  </>
                )}
              </ContentContainer>
            ))}
          </ListContainer>
        ))}
      <ButtonContainer>
        <Button onClick={updateMenuList}>변경</Button>
        {title === "MOBILE" && (
          <Button
            onClick={() => {
              handleOpenDialog(true);
            }}
          >{`권한설정`}</Button>
        )}
      </ButtonContainer>
      {isOpen && (
        <AsonicDialog
          title={"권한설정"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          width="1500px"
          minWidth="1500px"
          height="430px"
          minHeight="430px"
        >
          <SuperAdminPermissionSetting />
        </AsonicDialog>
      )}
    </Container>
  );
};

export default MenuCheckBoxList;
