import { useForm } from "react-hook-form";
import styled from "styled-components";
import TextInput from "../inputs/text-input";
import { Icon } from "@iconify/react";
import { useMemo } from "react";
import SearchIcon from "@iconify/icons-mdi/magnify";

export interface IList {
  name: string;
  value: string;
}

export interface SearchFieldValues {
  type?: string;
  value?: string;
  value2?: string;
}
export interface ISearchProps {
  listOfSearchType?: IList[];
  handleSearch?: (data: SearchFieldValues) => void;
}

const Container = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Label = styled.label`
  min-width: max-content;
`;

const Select = styled.select``;

const Option = styled.option``;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${props => props.theme.colors.green};
  :hover {
    color: ${props => props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.9);
  }
  cursor: pointer;
`;

function AsonicSearch({ listOfSearchType, handleSearch }: ISearchProps) {
  const { register, handleSubmit, watch, reset } = useForm<SearchFieldValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      type: listOfSearchType ? listOfSearchType[0]?.value : "",
      value: ""
    }
  });
  const data = watch();
  const type = useMemo(() => {
    if (data.type?.toLocaleLowerCase().includes("date")) {
      return "date";
    }
    // iss-1945 기간내 재직자 검색을 위해 추가
    if (data.type?.toLocaleLowerCase().includes("period")) {
      return "period";
    }
    return "text";
  }, [data.type]);

  return (
    <Container onSubmit={handleSearch && handleSubmit(handleSearch)}>
      <Label htmlFor="search">검색</Label>
      <Select
        {...register("type")}
        onChange={e => {
          register("type").onChange(e); // react-hook-form 기본 동작 유지
          reset({
            // type은 새로 선택된 값으로, value는 빈 문자열로 초기화
            type: e.target.value,
            value: "",
            value2: "" // period type일 경우를 위해 value2도 초기화
          });
        }}
      >
        {listOfSearchType?.map(item => (
          <Option value={item.value} key={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
      <TextInput
        id="search"
        {...register("value")}
        type={type === "period" ? "date" : type}
      />
      {type === "period" && (
        <TextInput id="search-2" {...register("value2")} type={"date"} />
      )}
      <IconContainer onClick={handleSearch && handleSubmit(handleSearch)}>
        <Icon icon={SearchIcon} />
      </IconContainer>
    </Container>
  );
}

export default AsonicSearch;
