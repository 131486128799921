import styled from "styled-components";

const TRow = styled.tr`
  cursor: pointer;
  width: 100% !important;
  min-width: min-content;
  border-bottom: 1px solid ${props => props.theme.colors.littleLightGrey};
  &:hover {
    background-color: ${props => props.theme.colors.littleLightGrey};
    color: ${props => props.theme.colors.black};
  }
`;

export default TRow;
