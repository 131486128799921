import { IProps } from "./StatsTypes";
import useAsonicTab from "../../../../hooks/hooks-shared/use-asonic-tab";
import styled from "styled-components";
import AsonicTab from "../../../shared/asonic-tab";
import StatisticsList from "../statistics_list/statistics_list";
import StatisticsStatus from "../statistics_status/statistics_status";

const STATUS_TABS = {
  WORK_STATUS: "근무현황",
  STATISTICS_LIST: "통계목록"
} as const;

type STATUS_TABS = typeof STATUS_TABS;

type STATUS_TABS_KEY = keyof typeof STATUS_TABS;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 500px;
  margin-top: 10px;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.grey};
`;

const AdminStatsContainer = (props: IProps) => {
  const { list, selectedTab, handleSelectTab } = useAsonicTab<
    STATUS_TABS,
    STATUS_TABS_KEY
  >({
    tabs: STATUS_TABS
  });

  return (
    <Container>
      <AsonicTab<STATUS_TABS_KEY>
        list={list}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <Content>
        {selectedTab === "WORK_STATUS" && (
          <StatisticsStatus isAdmin={props.isAdmin} />
        )}
        {selectedTab === "STATISTICS_LIST" && (
          <StatisticsList isAdmin={props.isAdmin} />
        )}
      </Content>
    </Container>
  );
};

export default AdminStatsContainer;
