import * as ReactTable from "react-table";
import { faFolderPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import IconButton from "../../../../globalComponents/IconButton";
import { IApprovalNotificationTemplate } from "../../../../../hooks/approval-notification-management-hook/use-approval-notification-template";
import { useCallback } from "react";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import {
  AddApprovalNotificationDepartment,
  AddApprovalNotificationDepartmentVariables
} from "../../../../../__generated__/AddApprovalNotificationDepartment";
import {
  DeleteApprovalNotificationDepartment,
  DeleteApprovalNotificationDepartmentVariables
} from "../../../../../__generated__/DeleteApprovalNotificationDepartment";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import { colors } from "../../../../GlobalStyle/GlobalStyle";

interface Props {
  selectedTemplate?: IApprovalNotificationTemplate;
  selectedDeFromTable: ReactTable.Row<any>;
  cacheId?: string | null;
}

const Container = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
`;

const MUTATION_ADD_APPROVAL_NOTIFICATION_DEPARTMENT = gql`
  mutation AddApprovalNotificationDepartment(
    $templateIdx: Int!
    $selectedDepartmentId: String!
  ) {
    addApprovalNotificationDepartment(
      templateIdx: $templateIdx
      selectedDepartmentId: $selectedDepartmentId
    ) {
      ok
      error
    }
  }
`;

const MUTATION_DELETE_APPROVAL_NOTIFICATION_DEPARTMENT = gql`
  mutation DeleteApprovalNotificationDepartment(
    $templateIdx: Int!
    $selectedDepartmentId: String!
  ) {
    deleteApprovalNotificationDepartment(
      templateIdx: $templateIdx
      selectedDepartmentId: $selectedDepartmentId
    ) {
      ok
      error
    }
  }
`;

function ApprovalNotificationDepartmentFooter({
  selectedDeFromTable,
  selectedTemplate
}: Props) {
  const selectedDepartment = useReactiveVar(
    listOfApolloVar.selectedDepartmentVar
  );
  const selectedDepartmentInfo = useReactiveVar(
    listOfApolloVar.selectedDepartmentInfoVar
  );
  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const [addApprovalNotificationDepartment, { client }] = useMutation<
    AddApprovalNotificationDepartment,
    AddApprovalNotificationDepartmentVariables
  >(MUTATION_ADD_APPROVAL_NOTIFICATION_DEPARTMENT, {
    async update(_, { data }) {
      if (
        data?.addApprovalNotificationDepartment.ok &&
        selectedDepartmentInfo &&
        selectedTemplate
      ) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedDepartmentInfo.name}를 추가했습니다.`,
          MessageTypes.SUCCESS
        );
        await client.resetStore();
      } else if (
        !data?.addApprovalNotificationDepartment.ok &&
        selectedDepartmentInfo &&
        selectedTemplate
      ) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedDepartmentInfo.name}를 추가하지 못했습니다.`,
          MessageTypes.ERROR
        );
      }
    }
  });

  const [deleteApprovalNotification] = useMutation<
    DeleteApprovalNotificationDepartment,
    DeleteApprovalNotificationDepartmentVariables
  >(MUTATION_DELETE_APPROVAL_NOTIFICATION_DEPARTMENT, {
    update(cache, { data }) {
      if (data?.deleteApprovalNotificationDepartment.ok && selectedTemplate) {
        cache.evict({
          id: `ApprovalNotificationDepartment:${selectedDeFromTable?.original.departmentId}`
        });
        cache.modify({
          id: `CountListOfApprovalNotificationDepartmentOutput`,
          fields: {
            total(prev) {
              return prev - 1;
            }
          }
        });
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedDeFromTable?.original?.departName}를 삭제했습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (
        !data?.deleteApprovalNotificationDepartment.ok &&
        selectedTemplate
      ) {
        handleToast(
          `${selectedTemplate.alarmName} 템플릿에 ${selectedDeFromTable?.original?.departName}를 삭제하지 못했습니다.`,
          MessageTypes.ERROR
        );
      }
    }
  });

  const { handleSubmit } = useForm();
  const addDepartmentAppliedToTheTemplate = useCallback(() => {
    if (selectedTemplate && selectedDepartment) {
      addApprovalNotificationDepartment({
        variables: {
          templateIdx: selectedTemplate.templateIdx,
          selectedDepartmentId: selectedDepartment
        }
      });
    }
  }, [selectedTemplate, selectedDepartment, addApprovalNotificationDepartment]);

  const deleteDepartmentAppliedToTheTemplate = useCallback(() => {
    if (selectedTemplate && selectedDeFromTable?.original?.departmentId) {
      deleteApprovalNotification({
        variables: {
          templateIdx: selectedTemplate?.templateIdx,
          selectedDepartmentId: selectedDeFromTable?.original?.departmentId
        }
      });
    }
  }, [selectedTemplate, selectedDeFromTable, deleteApprovalNotification]);

  return (
    <Container>
      <ReactTooltip type="success" id="addDepartment">
        <span>부서 추가</span>
      </ReactTooltip>
      <IconButton
        data-for="addDepartment"
        icon={faFolderPlus}
        data-tip
        onClick={handleSubmit(addDepartmentAppliedToTheTemplate)}
        disabled={!selectedDepartment || !selectedTemplate}
      />
      {selectedDeFromTable && (
        <ReactTooltip type="success" id="deleteDepartment">
          <span>부서 추가</span>
        </ReactTooltip>
      )}
      <IconButton
        icon={faTrashAlt}
        color={colors.darkRed}
        data-for="deleteDepartment"
        data-tip
        onClick={handleSubmit(deleteDepartmentAppliedToTheTemplate)}
        disabled={!selectedDeFromTable}
      />
      <ToastMessage
        messageTypes={toastMessageType}
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </Container>
  );
}

export default ApprovalNotificationDepartmentFooter;
