import { useCallback, useEffect, useState } from "react";

interface IContextMenuPosition {
  pageX: number;
  pageY: number;
}

export interface IContextMenu extends IContextMenuPosition {
  isContextMenu: boolean;
}

function useContextMenu() {
  const [selectedTarget, setSelectedTarget] = useState<string>("");
  const [isContextMenu, setIsContextMenu] = useState<boolean>(false);
  const [ctxMenuPosition, setCtxMenuPosition] = useState<IContextMenuPosition>({
    pageX: 0,
    pageY: 0
  });

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      setSelectedTarget(event.currentTarget.id);
      setCtxMenuPosition({
        pageX: event.pageX,
        pageY: event.pageY
      });
      setIsContextMenu(true);
    },
    []
  );

  const disableContextMenu = useCallback(
    event => {
      const contextButton = 2;
      if (event.button !== contextButton) {
        setIsContextMenu(false);
        setSelectedTarget("");
        //   } else if (isContextMenu && !event.target?.id.includes("context-menu")) {
        //     setIsContextMenu(false);
        //     setSelectedTarget("");
      }
    },
    [isContextMenu]
  );

  useEffect(() => {
    if (selectedTarget) {
      document.addEventListener("click", disableContextMenu);
      document.addEventListener("contextmenu", disableContextMenu);
    } else {
      document.removeEventListener("click", disableContextMenu);
      document.removeEventListener("contextmenu", disableContextMenu);
    }
    return () => {
      document.removeEventListener("click", disableContextMenu);
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, [disableContextMenu, selectedTarget]);

  return { isContextMenu, ctxMenuPosition, handleContextMenu };
}

export default useContextMenu;
