export const COLUMN_FOR_VALUE = {
  isCheck: "선택",
  departmentName: "부서",
  employeeId: "사번(ERP)",
  employeeName: "이름(ERP)",
  monthStartDay: "시작날짜",
  monthLastDay: "종료날짜",
  isClosingStatus: "마감여부",
  workType: "시급제/연봉제",
  basicTimeOrDay: "기본근무(시간/일)",
  extMin: "연장근무시간",
  nightMin: "야간근무시간",
  holidayBasicMin: "휴일기본근무시간",
  holidayExtMin: "휴일연장근무시간",
  holidayNightMin: "휴일야간근무시간",
  holidayBasicMinLaborday: "근로자의날기본근무시간",
  holidayExtMinLaborday: "근로자의날연장근무시간",
  holidayNightMinLaborday: "근로자의날야간근무시간",
  monthRestMin: "주휴시간합계",
  monthWeekTot: "주수합계"
} as const;

export const TABLE_CONFIG = {
  DEFAULT_PAGE_SIZE: 10,
  MIN_COLUMN_WIDTH: 70,
  DEFAULT_COLUMN_WIDTH: 120
};

export const indexDBTabName = "마감정보";
