import styled from "styled-components";

interface IProps {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  isRequired?: boolean;
  isCenter?: boolean;
  minHeight?: string;
  gridNumber?: number;
  $isLongTitle?: boolean;
}

const Container = styled.div<{ minHeight?: string; gridNumber?: number }>`
  display: flex;
  flex: 1;
  min-height: ${props => (props.minHeight ? props.minHeight : "40px")};
  max-height: ${props => (props.minHeight ? props.minHeight : "40px")};
  position: relative;
  grid-column: ${props =>
    props.gridNumber ? `${props.gridNumber} / span 2` : ""};
`;

const Header = styled.div<{ isCenter?: boolean; $isLongTitle?: boolean }>`
  display: flex;
  justify-content: ${props => {
    if (props.isCenter) {
      return "center";
    }
    return "start";
  }};
  padding: 0px 10px 0px 10px;
  background-color: ${props => props.theme.colors.lightGrey};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.littleLightGrey};
  gap: 10px;
  width: auto;
  min-width: 75px;
  white-space: pre-wrap;
  text-align: center;
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.littleLightGrey};
  padding: 0px 10px 0px 10px;
  gap: 5px;
  flex: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: max-content;
`;

const Title = styled.h4`
  font-weight: normal;
`;

const RequiredTitle = styled.span<{ isRequired?: boolean; isCenter?: boolean }>`
  display: ${props => {
    if (!props.isRequired && props.isCenter) {
      return "none";
    }
    return "flex";
  }};
  color: ${props => {
    if (props.isRequired) {
      return props.theme.colors.darkRed;
    }
    return props.theme.colors.lightGrey;
  }};
`;

function FormRow({
  title,
  subTitle,
  isRequired,
  isCenter,
  minHeight,
  gridNumber,
  $isLongTitle,
  children
}: IProps) {
  return (
    <Container minHeight={minHeight} gridNumber={gridNumber}>
      <Header isCenter={isCenter} $isLongTitle={$isLongTitle}>
        {isRequired && (
          <RequiredTitle isRequired={isRequired} isCenter={isCenter}>
            *
          </RequiredTitle>
        )}
        <TitleContainer>
          <Title>{title}</Title>
          {subTitle && <Title>{subTitle}</Title>}
        </TitleContainer>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
}

export default FormRow;
