import React, { useCallback, useState } from "react";
import uiString from "../../string.json";
import {
  DialogContainer,
  DialogContentContainer,
  Date,
  Time,
  TimeDivider,
  ButtonContainer
} from "./styled";
import { useReactiveVar } from "@apollo/client";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import moment from "moment";
import { crc16xmodem } from "crc";
import Button from "../../../globalComponents/Button";
import listOfApolloVar from "../../../../apollo/apollo-var";
import FormRow from "../../../shared/form-row/form-row";
import StyleInput from "../../../inputs/style-input";
import { colors } from "../../../GlobalStyle/GlobalStyle";

const EmergencyKeyDialog = props => {
  const { handleOpenEmergencyKeyDialog } = props;

  const [dateValue, setDateValue] = useState(moment().format("YYYY-MM-DD")),
    [firstTime, setFirstTime] = useState(moment().format("HH:mm")),
    [lastTime, setLastTime] = useState(moment().add(1, "h").format("HH:mm")),
    [emergencyKey, setEmergencyKey] = useState("");

  const handleDateValue = event => {
    setDateValue(event.target.value);
  };

  const handleFirstTime = event => {
    setFirstTime(event.target.value);
  };

  const handleLastTime = event => {
    setLastTime(event.target.value);
  };

  const handleEmergencyKey = useCallback(
    id => () => {
      if (id && dateValue && firstTime && lastTime) {
        const FIRST_TIME = `${firstTime.split(":")[0]}${
          firstTime.split(":")[1]
        }`;
        const LAST_TIME = `${lastTime.split(":")[0]}${lastTime.split(":")[1]}`;
        const newFormatDateValue = moment(dateValue, "YYYY-MM-DD").format(
          "YYYYMMDD"
        );
        const Key = `${FIRST_TIME}${LAST_TIME}${crc16xmodem(
          `${id}${newFormatDateValue}${FIRST_TIME}${LAST_TIME}`
        ).toString(10)}`;
        setEmergencyKey(Key);
      }
    },
    [dateValue, firstTime, lastTime]
  );

  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const userId = React.useMemo(
    () => listOfEmployeeId[listOfEmployeeId.length - 1],
    [listOfEmployeeId]
  );
  return (
    <AsonicDialog
      title={uiString.emergencyKeyDialog.title}
      handleClose={handleOpenEmergencyKeyDialog}
      height="220px"
      minHeight="220px;"
    >
      <DialogContainer>
        <DialogContentContainer>
          <FormRow title={uiString.emergencyKeyDialog.date}>
            <Date type="date" value={dateValue} onChange={handleDateValue} />
          </FormRow>
          <FormRow title={uiString.emergencyKeyDialog.temporarilyTime}>
            <Time type="time" value={firstTime} onChange={handleFirstTime} />
            <TimeDivider> ~ </TimeDivider>
            <Time type="time" value={lastTime} onChange={handleLastTime} />
          </FormRow>
          <FormRow title={uiString.emergencyKeyDialog.releaseKey}>
            <StyleInput
              type="text"
              value={emergencyKey}
              readOnly
              minWidth="233px"
            />
          </FormRow>
        </DialogContentContainer>
        <ButtonContainer>
          <Button
            backgroundColor={colors.darkBlue}
            hoverBackgroundColor={colors.skyBlue}
            onClick={handleEmergencyKey(userId)}
          >
            키생성
          </Button>
        </ButtonContainer>
      </DialogContainer>
    </AsonicDialog>
  );
};

export default EmergencyKeyDialog;
