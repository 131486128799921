import ConfirmDialog from "../../../confirm-dialog/confirm-dialog";
import ContextMenu from "../../../context-menu/context-menu";
import { downloadFileFromServer } from "../../statistics/Utils";
import MainViewContext from "../../store";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import moment from "moment";
import styled from "styled-components";
import { useReactiveVar } from "@apollo/client";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import * as ReactTable from "react-table";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import { SearchFieldValues } from "../../../asonic-table/asonic-search";
import useContextMenu from "../../../../hooks/use-context-menu/use-context-menu";
import useCheckWebAuthFn from "../../../../hooks/use-check-web-auth-fn";
import useWorkingTemplate, {
  IWorkingTemplate
} from "../../../../hooks/use-working-template/use-working-template";
import useConfirmDialog from "../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import WorkingTemplateManagementMenu from "../../../Rightmenu/working-template-management-menu/working-template-management-menu";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import ShowGraphMenu from "../../../Rightmenu/show-graph-menu/show-graph-menu";
import ChartDialogContainer from "../../../Rightmenu/ChartDialogContainer";
import PhoneNumberRegister from "../../../Rightmenu/phone-number-register/phone-number-register";
import PhoneNumberRegisterDialogContianer from "../../../Rightmenu/PhoneNumberRegisterDialogContainer";
import EmergencyKeyIssuance from "../../../Rightmenu/emergency-key-Issuance/emergency-key-Issuance";
import { Role } from "../../../../user-types";
import InitPassword from "../../../Rightmenu/init-password/init-password";
import useInitPassword from "../../../../hooks/use-init-password";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import EmergencyKeyDialog from "../EmergencyKeyDialog";
import listOfApolloVar from "../../../../apollo/apollo-var";
import AsonicUserProfile from "../../../asonic-table/asonic-user-profile-of-table";
import {
  EmployeeInformationV2,
  FieldSort,
  Type_Of_Employee_Information_V2,
  useCountListOfEmployeeInformationV2LazyQuery,
  useDownloadListOfEmployeeInformationExcelV2LazyQuery,
  useGetEmployeeInformationV2LazyQuery,
  useUpdateWorkingTemplateMutation
} from "../../../../generated/graphql";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import UserInfoSectionHeader from "./user_info_section_container";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../table_v2/table_v2";
import TableLayoutFooter from "../../../table_layout/table_layout_footer";
import PageController from "../../../table/page_controller";
import useNewSortBy from "../../../../hooks/use-new-sort-by/use-new-sort-by";

interface IProps {
  userInfoHeight: number;
  userWindowMouseDown: () => void;
}

enum COLUMN_FOR_VALUE {
  empName = "성명",
  employeeId = "아이디",
  departName = "부서명",
  workingName = "근무정책",
  endpointCount = "Endpoint수량",
  userJob = "직책명",
  workStatus = "재직/퇴직",
  localStatus = "소속상태",
  joiningDate = "입사일자",
  leavingDate = "퇴사일자",
  birthday = "생년월일"
}

export type TYPE_FOR_DASH_BOARD_TABLE = keyof typeof COLUMN_FOR_VALUE;

type SearchTypes = TYPE_FOR_DASH_BOARD_TABLE | "employeeInPeriod";

interface SearchListItem {
  value: SearchTypes;
  name: string;
}

const DragContainer = styled.div<{ userInfoHeight: number }>`
  position: relative;
  display: flex;
  min-height: 300px;
  height: ${props =>
    props.userInfoHeight ? `${props.userInfoHeight}px` : `50%`};
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const DragLine = styled.div`
  position: sticky;
  cursor: row-resize;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-bottom: 2px solid ${props => props.theme.colors.frameGrey};
  :hover {
    border-bottom: 2px solid ${props => props.theme.colors.lightMidNightBlue};
  }
  :active {
    border-bottom: 2px solid ${props => props.theme.colors.lightGreen};
  }
`;

const title = "직원정보";

function UserInfoSection({ userInfoHeight, userWindowMouseDown }: IProps) {
  const isChangedSelectedDepartment = useRef(false);
  const selectedDepartment = listOfApolloVar.selectedDepartmentVar();
  const { oldFieldSort, fieldSort, handleFieldSort } = useNewSortBy();
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const [isSearch, setIsSearch] = useState<boolean>(true);

  const [currentSearchType, setCurrentSearchType] =
    useState<Type_Of_Employee_Information_V2>(
      Type_Of_Employee_Information_V2.EmpName
    );
  const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] = useState<Type_Of_Employee_Information_V2>(
    Type_Of_Employee_Information_V2.EmpName
  );

  // 사용자 아이디 구하기
  // employeeId 전역 구하기
  // TIP: signInReducer로 employeeId를 구할 수 있다
  const {
    listOfWorkingTemplate,
    selectedWorkingTemplate,
    handleSelectWorkingTemplate
  } = useWorkingTemplate();
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: {
      loginIdInfo: authCheck,
      checkWebAuthFn,
      employee_id: employeeId
    }
  } = rootStore;
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: rootStore?.signInReducer?.webMenuList[0]?.web_etc_flag
  });

  const [isEmergencyKeyDialog, setIsEmergencyKeyDialog] = useState(false);
  const handleOpenEmergencyKeyDialog = useCallback((isTrue: boolean) => {
    setIsEmergencyKeyDialog(isTrue);
  }, []);

  const [isOpenChartDialog, setIsOpenChartDialog] = useState<boolean>(false);
  const [isOpenPhoneNumberRegisterDialog, setIsOpenPhoneNumberRegisterDialog] =
    useState<boolean>(false);

  const handleOpenChartDialog = useCallback((isTrue: boolean) => {
    setIsOpenChartDialog(isTrue);
  }, []);

  const handlePhoneNumberRegisterDialog = useCallback((isTrue: boolean) => {
    setIsOpenPhoneNumberRegisterDialog(isTrue);
  }, []);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const [updateWorkingTemplate] = useUpdateWorkingTemplateMutation({
    onError(error) {
      handleApolloErrorLog(error);
    },
    update(cache, { data }, { variables }) {
      if (data?.updateWorkingTemplate.ok && variables?.employeeIdList) {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage(
          `${selectedWorkingTemplate?.name || "미사용"}으로 변경하였습니다.`
        );
        if (Array.isArray(variables?.employeeIdList)) {
          variables?.employeeIdList.forEach(item => {
            cache.modify({
              id: `EmployeeInformation:${item}`,
              fields: {
                workingName() {
                  return selectedWorkingTemplate?.name;
                }
              }
            });
          });
        }
      } else if (
        !data?.updateWorkingTemplate.ok &&
        data?.updateWorkingTemplate.error
      ) {
        handleErrorLog(data?.updateWorkingTemplate.error);
        handleToastMessageType(MessageTypes.WARNING);
        handleMessage(
          `${
            selectedWorkingTemplate?.name || "미사용"
          }으로 변경하지 못했습니다.`
        );
      }
      handleIsOpen(false);
      handleIsToastMessageOpen(true);
    }
  });

  //   const [getTotal, { data: totalData }] = useLazyQuery<
  //     CountListOfEmployeeInformation,
  //     CountListOfEmployeeInformationVariables
  //   >(QUERY_COUNT_LIST_OF_EMPLOYEE_INFORMATION);

  const [getTotalV2, { data: totalDataV2 }] =
    useCountListOfEmployeeInformationV2LazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  //   const [getEmployeeInformation, { data, loading }] = useLazyQuery<
  //     GetEmployeeInformation,
  //     GetEmployeeInformationVariables
  //   >(QUERY_GET_EMPLOYEE_INFORMATION, {
  //     onError(error) {
  //       console.log(error);
  //     },
  //     onCompleted(data) {
  //       setIsSearch(false);
  //       if (
  //         !data.getEmployeeInformation.ok &&
  //         data.getEmployeeInformation.error
  //       ) {
  //         console.log(data.getEmployeeInformation.error);
  //       }
  //     }
  //   });

  const [getEmployeeInformationV2, { data, loading }] =
    useGetEmployeeInformationV2LazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        setIsSearch(false);
        if (
          !data.getEmployeeInformationV2.ok &&
          data.getEmployeeInformationV2.error
        ) {
          console.log(data.getEmployeeInformationV2.error);
        }
      }
    });

  //   const [downloadListOfEmployeeInformationExcel] = useLazyQuery<
  //     DownloadListOfEmployeeInformationExcel,
  //     DownloadListOfEmployeeInformationExcelVariables
  //   >(QUERY_DOWNLOAD_LIST_OF_EMPLOYEE_INFORMATION_EXCEL, {
  //     fetchPolicy: "no-cache",
  //     onCompleted(data) {
  //       if (
  //         data.downloadListOfEmployeeInformationExcel.ok &&
  //         data.downloadListOfEmployeeInformationExcel.excel
  //       ) {
  //         downloadFileFromServer(
  //           data.downloadListOfEmployeeInformationExcel.excel,
  //           `${moment().format("YYYY-MM-DD-hh-mm-ss")}-list-of-employee.csv`
  //         );
  //       }
  //     }
  //   });

  const [downloadListOfEmployeeInformationExcelV2] =
    useDownloadListOfEmployeeInformationExcelV2LazyQuery({
      fetchPolicy: "no-cache",
      onCompleted(data) {
        if (
          data.downloadListOfEmployeeInformationExcelV2.ok &&
          data.downloadListOfEmployeeInformationExcelV2.excel
        ) {
          downloadFileFromServer(
            data.downloadListOfEmployeeInformationExcelV2.excel,
            `${moment().format("YYYY-MM-DD-hh-mm-ss")}-list-of-employee.csv`
          );
        }
      }
    });

  // EmployeeInformationV2
  const columns: ReactTable.Column<EmployeeInformationV2>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    let newListOfColumn: ReactTable.Column<EmployeeInformationV2>[] = [];
    listOfColumn.forEach(item => {
      let width = 120;
      if (item === "departName" || item === "workingName") {
        width = 220;
      }
      if (item === "endpointCount") {
        width = 100;
      }
      let sticky = "";
      if (sFixedColumnNumber !== undefined) {
        if (fixedColumnName.length > 0) {
          fixedColumnName.forEach((fColumn, fColumnIndex) => {
            if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
              sticky = "left";
            }
          });
        }
      }
      const newColumn = {
        Header: COLUMN_FOR_VALUE[item as TYPE_FOR_DASH_BOARD_TABLE],
        accessor: item as TYPE_FOR_DASH_BOARD_TABLE,
        width,
        sticky,
        Cell(cell: ReactTable.Cell<EmployeeInformationV2>) {
          const INHERITANCE = 100000000;
          if (cell.column.Header === "근무시간계획정책" && !cell.value) {
            return "미사용";
          }
          if (cell.column.Header === "근무정책" && !cell.value) {
            if (cell.row.original.workingTemplateIdx === INHERITANCE) {
              return "상속";
            }
            return "미사용";
          }
          if (cell.column.Header === "성명") {
            return <AsonicUserProfile name={cell.value} />;
          }
          return cell.value;
        }
      };
      newListOfColumn.push(newColumn);
    });

    return newListOfColumn;
  }, [sFixedColumnNumber, fixedColumnName]);

  const list = useMemo(() => {
    return data?.getEmployeeInformationV2.listOfEmployee || [];
  }, [data]);

  const downloadExcel = useCallback(() => {
    if (selectedDepartment) {
      downloadListOfEmployeeInformationExcelV2({
        variables: {
          employeeId,
          selectedDepartmentId: selectedDepartment,
          searchType: searchType,
          searchValue
        }
      });
    }
  }, [
    selectedDepartment,
    downloadListOfEmployeeInformationExcelV2,
    searchType,
    searchValue
  ]);

  const total = useMemo(() => {
    if (
      totalDataV2?.countListOfEmployeeInformationV2.ok &&
      totalDataV2?.countListOfEmployeeInformationV2.total
    ) {
      return totalDataV2?.countListOfEmployeeInformationV2.total;
    }
    return 0;
  }, [totalDataV2]);

  const listOfSearchType = useMemo(() => {
    const searchListType: SearchListItem[] = Object.keys(COLUMN_FOR_VALUE).map(
      item => ({
        value: item as TYPE_FOR_DASH_BOARD_TABLE,
        name: COLUMN_FOR_VALUE[item as TYPE_FOR_DASH_BOARD_TABLE]
      })
    );

    // iss-1945 기간내 재직자 검색을 위해 변수에 추가했지만, combobox에는 추가하고, table에는 추가하지 않음
    searchListType.push({
      value: "employeeInPeriod",
      name: "기간내 재직자"
    });

    return searchListType;
  }, []);

  const handleSearch = useCallback(
    (data: SearchFieldValues) => {
      if (!data.type) {
        return;
      }

      const INIT_PAGE = 1;
      setCurrentSearchType(data.type as Type_Of_Employee_Information_V2);
      handleCurrentPage(INIT_PAGE);

      let tmpSearchValue = data.value;
      // iss-1945 기간내 재직자 검색을 위해 추가
      if (data.type === "employeeInPeriod" && tmpSearchValue) {
        setCurrentSearchValue(`${tmpSearchValue}~${data.value2}`);
        tmpSearchValue = `${tmpSearchValue}~${data.value2}`;
      }

      // searchValue와 searchType은 Excel download에 사용
      setSearchType(data.type as Type_Of_Employee_Information_V2);
      setSearchValue(tmpSearchValue ?? "");
      getTotalV2({
        variables: {
          employeeId,
          selectedDepartmentId: selectedDepartment,
          searchType: data.type as Type_Of_Employee_Information_V2,
          searchValue: tmpSearchValue
        }
      });
    },
    [getTotalV2, selectedDepartment, handleCurrentPage, employeeId]
  );

  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();
  const table = ReactTable.useTable<EmployeeInformationV2>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } = useDnd<EmployeeInformationV2>({
    columns: table.visibleColumns,
    setColumnOrder: table.setColumnOrder,
    title: `${title}-for-ordering-column`,
    setFixedColumnName
  });

  const selectedRow: ReactTable.Row<EmployeeInformationV2> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const selectTemplate = useCallback(
    (temp: IWorkingTemplate) => () => {
      handleSelectWorkingTemplate(temp);
      handleConfirmMessage({
        title: `근무정책변경`,
        p: `${temp.name}으로 변경하시겠습니까?`,
        messageTypes: MessageTypes.INFO
      });
      handleIsOpen(true);
    },
    [handleSelectWorkingTemplate, handleConfirmMessage, handleIsOpen]
  );

  const handleConfirm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const listOfUserId = table.selectedFlatRows.map(item => {
        return item.original.employeeId;
      }, []);
      updateWorkingTemplate({
        variables: {
          employeeIdList: listOfUserId,
          workingTemplateIdx: selectedWorkingTemplate?.working_templete_idx
        }
      });
    },
    [table.selectedFlatRows, selectedWorkingTemplate, updateWorkingTemplate]
  );

  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { handleInitPassword } = useInitPassword({
    handleMessage,
    handleIsToastMessageOpen,
    handleToastMessageType,
    selectedEmployeeId: listOfEmployeeId[listOfEmployeeId.length - 1]
  });

  useEffect(() => {
    listOfApolloVar.selectedListOfEmployeeIdVar(
      table.selectedFlatRows.map(item => item.original.employeeId)
    );
    listOfApolloVar.selectedListOfEmployeeVarV2(table.selectedFlatRows);
  }, [table.selectedFlatRows]);

  useEffect(() => {
    if (selectedDepartment) {
      getTotalV2({
        variables: {
          employeeId,
          selectedDepartmentId: selectedDepartment,
          searchType,
          searchValue: ""
        }
      });
    }
  }, [getTotalV2, selectedDepartment, employeeId]);

  useEffect(() => {
    if (selectedDepartment && !isChangedSelectedDepartment.current) {
      handleCurrentPage(1);
      isChangedSelectedDepartment.current = true;
    }
    return () => {
      isChangedSelectedDepartment.current = false;
    };
  }, [selectedDepartment, handleCurrentPage]);

  useEffect(() => {
    if (selectedDepartment) {
      isChangedSelectedDepartment.current = false;
      getEmployeeInformationV2({
        variables: {
          page: currentPage,
          take: take,
          employeeId,
          selectedDepartmentId: selectedDepartment,
          searchType,
          searchValue,
          fieldSort: oldFieldSort as FieldSort | null | undefined
        }
      });
    }
  }, [
    selectedDepartment,
    getEmployeeInformationV2,
    currentPage,
    take,
    employeeId,
    searchType,
    searchValue,
    oldFieldSort
  ]);

  useEffect(() => {
    if (
      !data?.getEmployeeInformationV2.ok &&
      data?.getEmployeeInformationV2.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.getEmployeeInformationV2.error);
      handleIsToastMessageOpen(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsToastMessageOpen]);

  useEffect(() => {
    if (isSearch) {
      setSearchValue(currentSearchValue);
      setSearchType(currentSearchType);
    }
  }, [isSearch, currentSearchValue, currentSearchType]);

  useEffect(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <DragContainer userInfoHeight={userInfoHeight}>
      <Container>
        <UserInfoSectionHeader<EmployeeInformationV2>
          columns={table.columns as TColumn<EmployeeInformationV2>[]}
          table={table}
          title={title}
          headerTitleList={Object.values(COLUMN_FOR_VALUE)}
          take={take}
          handleTake={handleTake}
          count={total}
          handleCurrentPage={handleCurrentPage}
          downloadExcel={downloadExcel}
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          setIsActiveFilter={setIsActiveFilter}
        />
        <TableLayoutContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title={title}
                  selectedRow={selectedRow}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  height={height}
                  width={width}
                  moveColumn={moveColumn}
                  handleContextMenu={handleContextMenu}
                  loading={loading}
                />
              );
            }}
          </AutoSizer>
        </TableLayoutContainer>
        <TableLayoutFooter>
          <PageController
            currentPage={currentPage}
            totalPage={Math.ceil(total / take)}
            handleCurrentPage={handleCurrentPage}
          />
        </TableLayoutFooter>
        {/* <AsonicTable<EmployeeInformationV2>
          title={title}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          take={take}
          handleTake={handleTake}
          total={total}
          totalPage={Math.ceil(total / take)}
          downloadExcel={downloadExcel}
          handleSelectRow={handleSelectRow}
          isLoading={loading}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          handleContextMenu={handleContextMenu}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          handleFieldSort={handleFieldSort}
          fieldSort={fieldSort}
          columns={
            userColumns as TColumn<EmployeeInformationV2>[]
          }
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
        /> */}
      </Container>
      <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
        {webMenuAuthList.serviceManagementInCtx && (
          <WorkingTemplateManagementMenu
            workingTemplate={listOfWorkingTemplate}
            selectTemplate={selectTemplate}
          />
        )}
        {webMenuAuthList.showGraph && (
          <ShowGraphMenu handleChartDialog={handleOpenChartDialog} />
        )}
        {webMenuAuthList.phoneNumber && authCheck && (
          <PhoneNumberRegister
            handlePhoneNumberRegisterDialog={handlePhoneNumberRegisterDialog}
          />
        )}
        {authCheck === Role.SUPER_ADMIN &&
          webMenuAuthList.emergencyKeyIssuance && (
            <EmergencyKeyIssuance
              handleOpenEmergencyKeyDialog={handleOpenEmergencyKeyDialog}
            />
          )}
        {webMenuAuthList.initPassword && (
          <InitPassword handleInitPassword={handleInitPassword} />
        )}
      </ContextMenu>
      <DragLine onMouseDown={userWindowMouseDown} />
      {selectedWorkingTemplate && isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpenChartDialog && (
        <ChartDialogContainer handleChartDialog={handleOpenChartDialog} />
      )}
      {isOpenPhoneNumberRegisterDialog && (
        <PhoneNumberRegisterDialogContianer
          handlePhoneNumberRegisterDialog={handlePhoneNumberRegisterDialog}
          authCheck={authCheck}
        />
      )}
      {isEmergencyKeyDialog && (
        <EmergencyKeyDialog
          isEmergencyKeyDialog={isEmergencyKeyDialog}
          handleOpenEmergencyKeyDialog={handleOpenEmergencyKeyDialog}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </DragContainer>
  );
}

export default UserInfoSection;
