import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { TOKEN } from "../../../apollo/apollo";
import {
  AddApprovalLineMutationVariables,
  ApprovalLineControlType,
  ApprovalLineUserEntity,
  Is_Default_Approval_Line,
  useAddApprovalLineMutation
} from "../../../generated/graphql";
import { handleDecodedToken } from "../../../Utils/tokenMaker";
import Button from "../../globalComponents/Button";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import StyleInput from "../../inputs/style-input";
import FormRow from "../../shared/form-row/form-row";
import { MessageTypes } from "../../toast-message/toast-message";
import { IEmployeeInformation } from "./list-of-selected-approval-user";
import { colors } from "../../GlobalStyle/GlobalStyle";

interface IFormValue {
  title: string;
  isDefaultApprovalLine: Is_Default_Approval_Line;
}
interface IProps {
  handleOpenDialog: (value: boolean) => void;
  userData: IEmployeeInformation[];
  handleToast: (message: string, type: MessageTypes) => void;
}

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const DialogContentContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

function AddApprovalLineDialog({
  handleOpenDialog,
  userData,
  handleToast
}: IProps) {
  const employeeId = useMemo((): string => {
    const token = localStorage.getItem(TOKEN);
    if (token) {
      const decodedData: any = handleDecodedToken(token);
      return decodedData.employee_id;
    }
    return "";
  }, []);

  const [addApprovalLine, { client }] = useAddApprovalLineMutation({
    onError(error) {
      handleToast("결재선을 새로저장하지 못했습니다.", MessageTypes.ERROR);
      console.log(error);
      handleOpenDialog(false);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    update(_, { data }, { variables }) {
      if (
        data?.addApprovalLine.ok &&
        data.addApprovalLine.lineIdx &&
        variables
      ) {
        client.resetStore();
        handleToast("결재선을 새로저장 했습니다.", MessageTypes.SUCCESS);
      } else if (data?.addApprovalLine.error) {
        handleToast(data?.addApprovalLine.error, MessageTypes.ERROR);
      }
      handleOpenDialog(false);
    }
  });
  const { register, handleSubmit, watch, setValue } = useForm<IFormValue>({
    defaultValues: {
      isDefaultApprovalLine: Is_Default_Approval_Line.Used
    }
  });
  const isDefaultApprovalLine = watch("isDefaultApprovalLine");

  const submit = useCallback(
    (data: IFormValue) => {
      const list: ApprovalLineUserEntity[] = userData.map((item, index) => ({
        employeeid: item.employeeId,
        order: index + 1,
        type: item.approvalType
      }));
      if (employeeId) {
        const payload: AddApprovalLineMutationVariables = {
          approvalLineControlType: ApprovalLineControlType.Add,
          user: employeeId,
          approvalLineName: data.title,
          isDefaultApprovalLine: data.isDefaultApprovalLine,
          count: userData.length,
          list
        };
        addApprovalLine({ variables: payload });
      }
    },
    [employeeId, userData, addApprovalLine]
  );
  return (
    <DialogContentContainer onSubmit={handleSubmit(submit)}>
      <DialogContent>
        <FormRow title="결재선 이름">
          <StyleInput {...register("title", { required: true })} required />
        </FormRow>
        <FormRow title="기본 결재선">
          <CheckBoxUi
            checked={isDefaultApprovalLine === Is_Default_Approval_Line.Used}
            onChange={event => {
              if (event.target.checked) {
                setValue(
                  "isDefaultApprovalLine",
                  Is_Default_Approval_Line.Used
                );
              } else {
                setValue(
                  "isDefaultApprovalLine",
                  Is_Default_Approval_Line.UnUsed
                );
              }
            }}
            htmlFor={"기본 결재선"}
            name={"기본 결재선"}
          />
        </FormRow>
      </DialogContent>
      <ButtonContainer>
        <Button
          customMinWidth="50px"
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
        >
          저장
        </Button>
        <Button
          backgroundColor={colors.darkRed}
          hoverBackgroundColor={colors.lightRed}
          customMinWidth="50px"
          onClick={() => {
            handleOpenDialog(false);
          }}
        >
          닫기
        </Button>
      </ButtonContainer>
    </DialogContentContainer>
  );
}

export default AddApprovalLineDialog;
