import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useOpenDialog from "../../../../../hooks/use-open-dialog";
import {
  GetListOfApprovalNotificationTemplate,
  GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list
} from "../../../../../__generated__/GetListOfApprovalNotificationTemplate";
import MultiTab, { MultiTabType } from "../../../../multi-tab";
import ApprovalNotificationDepartmentSection from "./approval-notification-department-section";
import ApprovalNotificationDialog from "./approval-notification-dialog";
import ApprovalNotificationEmployeeSection from "./approval-notification-employee-section";
import ApprovalNotificationManagementDialogCtx from "./approval-notification-management-dialog.ctx";
import * as ReactTable from "react-table";
import { IAsonicRow } from "../../../../asonic-table/asonic-render-row";
import AsonicTable from "../../../../asonic-table/asonic-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import Button from "../../../../globalComponents/Button/Button";
import { colors } from "../../../../GlobalStyle/GlobalStyle";

enum TabType {
  EMPLOYEE = "정책적용직원",
  DEPARTMENT = "정책적용부서"
}

type MultiTabList = MultiTabType[];

const Container = styled.div`
  display: flex;
  flex: 10;
`;

const ListOfApprovalNotificationTemplatesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 150px;
  border-right: 1px solid ${props => props.theme.borderColor};
`;

const ListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 15px;
  background-color: ${props => props.theme.colors.lightMidNightBlue};
  border-bottom: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.colors.white};
`;

export const ButtonSection = styled.section`
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex: 1;
  max-height: 36px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.green};
  color: white;
  font-weight: bold;
  :hover {
    background-color: ${props => props.theme.colors.lightGreen};
    cursor: pointer;
  }
  :active {
    background-color: ${props => props.theme.colors.auroraGreen};
  }
`;

const MainSection = styled.section`
  display: flex;
  flex: 7.5;
  flex-direction: column;
  width: 0px;
`;

const MainTitleBar = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const QUERY_GET_LIST_OF_APPROVAL_NOTIFICATION_TEMPLATE = gql`
  query GetListOfApprovalNotificationTemplate {
    getListOfApprovalNotificationTemplate {
      ok
      error
      list {
        templateIdx
        alarmName
        description
      }
    }
  }
`;

const ApprovalNotificationManagement = () => {
  const [getListOfApprovalNotificationTemplate, { data, loading }] =
    useLazyQuery<GetListOfApprovalNotificationTemplate>(
      QUERY_GET_LIST_OF_APPROVAL_NOTIFICATION_TEMPLATE
    );

  const columns: ReactTable.Column<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[] =
    useMemo(() => {
      return [{ Header: "근무관리", accessor: "alarmName" }];
    }, []);

  const listOfTemplate: GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list[] =
    useMemo(() => {
      return data?.getListOfApprovalNotificationTemplate.list || [];
    }, [data]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    columns: approvalNotificationTemplateColumns
  } = ReactTable.useTable<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>(
    {
      columns,
      data: listOfTemplate
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect
  );

  const selectedRow:
    | IAsonicRow<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleSelectRow = useCallback(
    (
      row?: IAsonicRow<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
    ) => {},
    []
  );

  const { isOpen, handleOpenDialog } = useOpenDialog();

  const [list, setList] = useState<MultiTabList>([
    { name: TabType.EMPLOYEE, isSelected: true },
    { name: TabType.DEPARTMENT, isSelected: false }
  ]);

  const handleSelected = useCallback(
    (payload: MultiTabType) => {
      const newList = list.map(item => {
        if (item.name === payload.name) {
          return payload;
        } else {
          const newItem: MultiTabType = { ...item, isSelected: false };
          return newItem;
        }
      });
      setList(newList);
    },
    [list]
  );

  useEffect(() => {
    getListOfApprovalNotificationTemplate();
  }, [getListOfApprovalNotificationTemplate]);

  return (
    <ApprovalNotificationManagementDialogCtx.Provider
      value={{ selectedTemplate: selectedRow?.original }}
    >
      <Container>
        <ListOfApprovalNotificationTemplatesContainer>
          <ListTitle>결재알림 템플릿</ListTitle>
          <AsonicTable<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>
            title="결재알림 템플릿"
            handleSelectRow={handleSelectRow}
            isLoading={loading}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            columns={
              approvalNotificationTemplateColumns as TColumn<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[]
            }
            isTitleBar={false}
          />
          <Button
            backgroundColor={colors.darkBlue}
            hoverBackgroundColor={colors.skyBlue}
            onClick={() => {
              handleOpenDialog(true);
            }}
          >
            결재정보알림 템플릿 설정
          </Button>
        </ListOfApprovalNotificationTemplatesContainer>
        <MainSection>
          <MainTitleBar>
            <MultiTab list={list} handleSelected={handleSelected} />
          </MainTitleBar>
          {list[0].isSelected && <ApprovalNotificationEmployeeSection />}
          {list[1].isSelected && <ApprovalNotificationDepartmentSection />}
        </MainSection>
        {isOpen && (
          <ApprovalNotificationDialog
            handleOpenDialog={handleOpenDialog}
            selectedTemplate={selectedRow?.original}
            handleSelectRow={handleSelectRow}
            loading={loading}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            approvalNotificationTemplateColumns={
              approvalNotificationTemplateColumns as TColumn<GetListOfApprovalNotificationTemplate_getListOfApprovalNotificationTemplate_list>[]
            }
          />
        )}
      </Container>
    </ApprovalNotificationManagementDialogCtx.Provider>
  );
};

export default ApprovalNotificationManagement;
