import * as React from "react";
import { Container } from "./styled";
import { NewProps } from "../PhoneNumberRegisterDialogContainer";
import AsonicDialog from "../../../asonic-dialog/asonic-dialog";
import Button from "../../../globalComponents/Button";
import FormRow from "../../../shared/form-row/form-row";
import StyleInput from "../../../inputs/style-input";
import styled from "styled-components";
import { colors } from "../../../GlobalStyle/GlobalStyle";

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  width: 70%;
  min-width: 150px;
`;

const PhoneNumberRegisterDialog = (props: NewProps) => {
  const {
    handlePhoneNumberRegisterDialog,
    phoneNumbers,
    handlePhoneNumbers,
    handleSubmit,
    authCheck
  } = props;
  const RW_ADMINE = React.useMemo(() => 2, []);

  return (
    <AsonicDialog
      title="전화번호 등록"
      handleClose={handlePhoneNumberRegisterDialog}
      height="250px"
      minHeight="250px"
      width="300px"
    >
      <Container onSubmit={handleSubmit}>
        <FormRow title="전화번호 1">
          <StyleInput
            type="number"
            placeholder="번호만 입력해주세요"
            value={phoneNumbers[0]}
            onChange={handlePhoneNumbers(0)}
            disabled={authCheck === RW_ADMINE ? false : true}
          />
        </FormRow>
        <FormRow title="전화번호 2">
          <StyleInput
            type="number"
            placeholder="번호만 입력해주세요"
            value={phoneNumbers[1]}
            onChange={handlePhoneNumbers(1)}
            disabled={authCheck === RW_ADMINE ? false : true}
          />
        </FormRow>
        <FormRow title="전화번호 3">
          <StyleInput
            type="number"
            placeholder="번호만 입력해주세요"
            value={phoneNumbers[2]}
            onChange={handlePhoneNumbers(2)}
            disabled={authCheck === RW_ADMINE ? false : true}
          />
        </FormRow>
      </Container>
      <ButtonContainer>
        <Button
          backgroundColor={colors.darkBlue}
          hoverBackgroundColor={colors.skyBlue}
        >
          등록하기
        </Button>
      </ButtonContainer>
    </AsonicDialog>
  );
};

export default PhoneNumberRegisterDialog;
