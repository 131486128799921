import { useContext } from "react";
import MainViewContext from "../components/main-view/store";
import useCheckWebAuthFn from "../hooks/use-check-web-auth-fn";

function useGlobalHook() {
  const rootStore = useContext(MainViewContext);
  const {
    signInReducer: {
      checkWebAuthFn,
      webMenuList,
      employee_id: callEmployeeId,
      loginIdInfo: authCheck
    }
  } = rootStore;

  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: checkWebAuthFn?.working_auth_web,
    webEtcFlag: webMenuList[0]?.web_etc_flag
  });

  return {
    webMenuAuthList,
    callEmployeeId,
    authCheck
  };
}

export default useGlobalHook;
