import IndexedDBManager from "../../../Utils/indexed-db/indexed-db-manager";

// 근무관리정보의 탭에서 각종 설정을 저장
// 지금은 검색 정보만 저장
// 검색 정보를 브라우져 캐시에 저장하여 다음에 브라우저를 오픈했을 때도 검색 정보를 사용하도록 하는 용도
export const TABS_DB_SCHEME_OBJECT = {
  commute: "commuteManagementTab", // 출퇴근관리정보
  work: "workManagementInfoTab", // 근무관리정보
  leftseat: "leftseatManagementTab", // 이석관리
  workHourDaily: "workHourDailyInfoTab", // 일별근무시간정보
  workHourWeekly: "workHourWeeklyInfoTab", // 주별근무시간정보
  workHourMonthly: "workHourMonthlyInfoTab", // 월별근무시간정보
  closingInfo: "closingInfoTab" // 마감정보
} as const;

export type TABS_DB_SCHEME_OBJECT =
  (typeof TABS_DB_SCHEME_OBJECT)[keyof typeof TABS_DB_SCHEME_OBJECT];

export interface IStorageInfo {
  tabName: string;
  startDateFromDB?: string;
  endDateFromDB?: string;
}

export default class BrowserPermanentStorage {
  private dbManager: IndexedDBManager;
  private dbVersion: number = 1;

  constructor(storeName: string) {
    this.dbManager = new IndexedDBManager(storeName, this.dbVersion);
  }

  public async getInitialData(
    key: TABS_DB_SCHEME_OBJECT,
    fallback: IStorageInfo
  ): Promise<IStorageInfo> {
    try {
      const savedDate = await this.dbManager.loadData(key);
      return savedDate ? savedDate : fallback;
    } catch (error) {
      console.log(error);
      return fallback;
    }
  }

  public async saveSearchDate(
    key: TABS_DB_SCHEME_OBJECT,
    value: IStorageInfo
  ): Promise<void> {
    const prevValue = await this.dbManager.loadData(key);
    await this.dbManager.saveData(key, { ...prevValue, ...value });
  }
}
