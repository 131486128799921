import styled from "styled-components";
import ClosingInfoHeader from "./closing-info-header";
import { Suspense, useMemo, useState } from "react";
import Spinner from "../../../../../components/shared/spinner";
import * as ReactTable from "react-table";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import PageController from "../../../../../components/table/page_controller";
import { ClosingInfoEntity } from "../../../../../generated/graphql";
import ToastMessage from "../../../../../components/toast-message/toast-message";
import TableV2 from "../../../../../components/table_v2/table_v2";
import { AutoSizer } from "react-virtualized";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import { ClosingInfoProps, IClosingInfoEntity } from "./types";
import { COLUMN_FOR_VALUE } from "./constants";
import { createColumns } from "./columns";
import useClosingInfoDataFetcher from "./useClosingInfoDataFetcher";

const Container = styled.div<{ $isVisibility: boolean }>`
  display: flex;
  flex: ${props => (props.$isVisibility ? "1" : "0")};
  overflow-x: hidden;
  flex-direction: column;
  visibility: ${props => (props.$isVisibility ? "visible" : "hidden")};
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  will-change: scroll-position;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

function ClosingInfo({ isVisibility }: ClosingInfoProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    totalCount,
    data,
    loading,
    startDate,
    setStartDate,
    setEndDate,
    saveSearchDate,
    downloadExcel,
    currentPage,
    handleCurrentPage,
    take,
    handleTake,
    isToastMessageOpen,
    handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleSendClosingInfo,
    handleSendERPInfo
  } = useClosingInfoDataFetcher();

  const count: number = useMemo(() => {
    return totalCount?.countUserMonthlyWorkPlan.count ?? 0;
  }, [totalCount]);

  const list: ClosingInfoEntity[] = useMemo(() => {
    return data?.getClosingInfo.list ?? [];
  }, [data]);

  const columns: ReactTable.Column<IClosingInfoEntity>[] = useMemo(
    () => createColumns(sFixedColumnNumber ?? 0),
    [sFixedColumnNumber]
  );

  const table = ReactTable.useTable<IClosingInfoEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow: ReactTable.Row<IClosingInfoEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  const checkedEmployeeIds = useMemo(() => {
    return table.selectedFlatRows.map(row => row.original.employeeId ?? "");
  }, [table.selectedFlatRows]);

  return (
    <Container $isVisibility={isVisibility}>
      <ClosingInfoHeader
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        columns={table.columns as TColumn<IClosingInfoEntity>[]}
        table={table}
        setIsSearch={saveSearchDate}
        title="마감정보"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
        handleSendClosingInfo={handleSendClosingInfo}
        handleSendERPInfo={handleSendERPInfo}
        checkedEmployeeIds={checkedEmployeeIds}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="월별근무"
                  selectedRow={selectedRow}
                  height={height}
                  width={width}
                  loading={loading}
                  isCustomSelect
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableFooter>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ClosingInfo;
