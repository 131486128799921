import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

interface IProps extends UseFormRegisterReturn {
  title: string;
  placeholder?: string;
  value: string;
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  margin-top: 14px;
`;

const Label = styled.label<{ value?: string }>`
  position: absolute;
  top: ${props => (props.value ? "-14px" : "0px")};
  left: ${props => (props.value ? "0px" : "10px")};
  opacity: ${props => (props.value ? "1" : "0")};
  color: ${props => props.theme.colors.darkGrey};
  visibility: ${props => (props.value ? "visible" : "hidden")};
  transition: all 0.1s ease-in-out;
`;

const Input = styled.input`
  display: flex;
  flex: 1;
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  outline: none;
  transition: all 0.1s ease-in-out;
  :focus + ${Label} {
    top: -14px;
    left: 0px;
    opacity: 1;
    visibility: visible;
    color: black;
  }
`;

function TextInputWithUnderline(props: IProps, ref: any) {
  return (
    <Container>
      <Input
        {...props}
        ref={ref}
        id={props.title}
        placeholder={props.placeholder || ""}
        value={props.value || ""}
      />
      <Label {...props} htmlFor={props.title}>
        {props.title}
      </Label>
    </Container>
  );
}

export default forwardRef(TextInputWithUnderline);
