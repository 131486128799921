import styled from "styled-components";

interface IProps {
  $backGroundColor?: string;
}

const STabContainer = styled.div<IProps>`
  display: flex;
  height: 50px;
  align-items: flex-end;
  border-bottom: 1px solid ${props => props.theme.colors.frameGrey};
  gap: 8px;
  padding: 0px 10px;
  background-color: ${props => props.$backGroundColor ?? ""};
`;

export default STabContainer;
