import styled from "styled-components";

interface IProps {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  customMinWidth?: string;
  customMinHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
}

export default styled.button<IProps>`
  border-radius: 4px;
  min-height: ${props =>
    props.customMinHeight ? props.customMinHeight : "30px"};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  background-color: ${props =>
    props.backgroundColor ?? props.theme.colors.green};
  color: white;
  border: none;
  outline: none;
  padding: 6px;
  min-width: ${props => {
    return props.customMinWidth ?? "100px";
  }};
  max-width: ${props => props.maxWidth ?? ""};
  :hover {
    background-color: ${props =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.theme.colors.lightGreen};
  }
  :active {
    transform: scale(0.98);
  }
  opacity: ${props => (props.disabled ? "0.2" : "1")};
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(0, 0);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
  }

  &:hover::after {
    transform: scale(10, 10);
    opacity: 0.3;
  }
`;
