import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import menuSettingsApi from "../../../../api/menu-settings-api";
import settingsApi from "../../../../api/settings-api";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  ILoginIdInfo,
  LoginIdInfo
} from "../../../../hooks/use-check-web-auth-fn";
import useWebMenuList, {
  ETC_FLAG_KEY,
  RadioCategory,
  REPORT_FLAG_KEY,
  WeeklyWork
} from "../../../../hooks/use-super-admin/use-web-menu-list";
import { setUserInfo } from "../../../../redux/modules/sign-in/signInReducer";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import MenuCheckBoxList from "../MenuCheckBoxList";
import CheckBoxListContainer from "./checkbox-list-container";
import { WebMenuList } from "./MenuSettingsContainer";
import uiString from "../string.json";

type OnChange = React.ChangeEvent<HTMLInputElement>;
const WEB = "WEB";

function WebMenuSettings() {
  const isMount = useRef<boolean>(false);
  const {
    signInReducer: {
      webMenuList,
      mobileMenuList,
      checkWebAuthFn: { working_auth_web: workingAuthWeb }
    }
  } = useSelector((state: Reducers) => state);
  const [webMenuStatus, setWebMenuStatus] = useState<WebMenuList>(webMenuList);
  const [loginIdInfo, setLoginIdInfo] = useState<ILoginIdInfo>({
    working_auth_web: workingAuthWeb
  });

  const { webList, handleWebList, handleLoginIdInfo } = useWebMenuList({
    webMenuStatus,
    loginIdInfo
  });

  const handleReportFlag = (event: OnChange) => {
    return handleWebList({
      title: uiString.titles.statistics,
      key: REPORT_FLAG_KEY,
      value: event.target.value,
      isChecked: event.target.checked,
      list: webMenuStatus[0]
    });
  };

  const handleRadioButton = (
    event: OnChange,
    radioCategory?: string,
    status?: number
  ) => {
    let newIsChecked = event.target.checked;
    let newStateValue = event.target.value;

    // 주별근무(수당정보) 메뉴 변경
    if (radioCategory === RadioCategory.WEEKLY_WORK) {
      newIsChecked = parseInt(event.target.value) === WeeklyWork.ALL;
      newStateValue = status?.toString() || "";
    }

    return { newIsChecked, newStateValue };
  };

  const updateWebMenuStatus = (params: {
    title: string;
    key: string;
    value: string;
    isChecked: boolean;
  }) => {
    const newState = handleWebList({
      ...params,
      list: webMenuStatus[0]
    });
    setWebMenuStatus([newState]);
  };

  const handleOnChange = useCallback(
    () => (event: OnChange, radioCategory?: string, status?: number) => {
      if (!isMount.current) return;

      if (event.target.name === LoginIdInfo.WorkingAuthWeb) {
        const newLoginInfo = handleLoginIdInfo({
          title: event.target.title,
          key: event.target.name,
          value: event.target.value,
          isChecked: event.target.checked,
          list: loginIdInfo
        });
        setLoginIdInfo(newLoginInfo);
        return;
      }

      if (!webMenuStatus) return;

      // 통계 메뉴 변경
      if (event.target.name === REPORT_FLAG_KEY) {
        const newState = handleReportFlag(event);
        setWebMenuStatus([newState]);
        return;
      }

      // 기타 메뉴 변경
      // 체크박스인 경우에는 event.target 값을 그대로 사용
      let { newIsChecked, newStateValue } = {
        newIsChecked: event.target.checked,
        newStateValue: event.target.value
      };

      // 기타 메뉴 라디오 버튼인 경우 실행
      if (event.target.type === "radio" && event.target.name === ETC_FLAG_KEY) {
        ({ newIsChecked, newStateValue } = handleRadioButton(
          event,
          radioCategory,
          status
        ));
      }

      updateWebMenuStatus({
        title: event.target.title, // 라디오 버튼인 경우 라디오 버튼 카테고리 타이틀이 들어간다
        key: event.target.name, // 라디오 버튼인 경우 각각의 이름
        value: newStateValue, // 라디오 버튼인 경우 WEB_ETC_FLAG_VALUES 숫자 값
        isChecked: newIsChecked // 라디오 버튼인 경우 어떤 버튼의 활성화 옵션인지 확인 필요 (두개 이상인 경우 주의)
      });
    },
    [webMenuStatus, handleWebList, handleLoginIdInfo, loginIdInfo]
  );

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const dispatch = useDispatch();
  const setUser = useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );

  const updateMenuList = useCallback(async () => {
    try {
      const menuSettingsResult = await menuSettingsApi.updateWorkingAuthWeb({
        workingAuthWeb: loginIdInfo.working_auth_web
      });
      if (menuSettingsResult.data.ok) {
        const { data } = await settingsApi.updateWebMenuList(webMenuStatus[0]);

        if (data.ok) {
          setUser({
            menuList: [{ ...webMenuStatus[0], ...mobileMenuList[0] }],
            webMenuList: webMenuStatus,
            mobileMenuList,
            checkWebAuthFn: loginIdInfo
          });
          handleToast(
            "웹 메뉴 리스트를 업데이트 했습니다.",
            MessageTypes.SUCCESS
          );
        } else {
          handleToast(data.error, MessageTypes.ERROR);
        }
      }
    } catch (error) {
      console.log(error);
      handleToast(
        "웹 메뉴 리스트를 업데이트를 하지 못했습니다.",
        MessageTypes.ERROR
      );
    }
  }, [setUser, handleToast, webMenuStatus, mobileMenuList, loginIdInfo]);

  useEffect(() => {
    if (webMenuList && webMenuList.length > 0) {
      isMount.current = true;
      setWebMenuStatus(webMenuList);
    }
    return () => {
      isMount.current = false;
    };
  }, [webMenuList]);

  return (
    <CheckBoxListContainer>
      <MenuCheckBoxList
        title={WEB}
        list={webList}
        handleOnChange={handleOnChange()}
        updateMenuList={updateMenuList}
      />
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </CheckBoxListContainer>
  );
}

export default WebMenuSettings;
